var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "app-container", staticStyle: { overflow: "auto" } },
    [
      _c(
        "div",
        { ref: "headerQuery" },
        [
          _c(
            "el-row",
            { staticClass: "headerClass" },
            [
              _c(
                "el-col",
                {
                  staticStyle: {
                    "border-left": "5px solid #0073E9",
                    "padding-left": "10px",
                  },
                  attrs: { span: 14 },
                },
                [
                  _vm._v("集采药品目录 "),
                  _c(
                    "span",
                    {
                      staticStyle: {
                        "padding-left": "10px",
                        "font-size": "14px",
                      },
                    },
                    [_vm._v(_vm._s(_vm.batchInfo.docName))]
                  ),
                ]
              ),
              _c(
                "el-col",
                { attrs: { span: 10 } },
                [
                  _c(
                    "el-form",
                    {
                      ref: "queryForm",
                      staticStyle: { float: "right" },
                      attrs: { model: _vm.queryParams, inline: true },
                    },
                    [
                      _c(
                        "el-form-item",
                        { attrs: { label: "", prop: "searchValue" } },
                        [
                          _c("el-input", {
                            staticStyle: { width: "300px" },
                            attrs: {
                              placeholder: "请输入药品名称/采购组名称",
                              maxlength: "50",
                              clearable: "",
                            },
                            nativeOn: {
                              keydown: function ($event) {
                                if (
                                  !$event.type.indexOf("key") &&
                                  _vm._k(
                                    $event.keyCode,
                                    "enter",
                                    13,
                                    $event.key,
                                    "Enter"
                                  )
                                ) {
                                  return null
                                }
                                return _vm.handleQuery(1)
                              },
                            },
                            model: {
                              value: _vm.queryParams.searchValue,
                              callback: function ($$v) {
                                _vm.$set(_vm.queryParams, "searchValue", $$v)
                              },
                              expression: "queryParams.searchValue",
                            },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "el-form-item",
                        [
                          _c(
                            "el-button",
                            {
                              attrs: {
                                type: "primary",
                                icon: "el-icon-search",
                                size: "small",
                              },
                              on: {
                                click: function ($event) {
                                  return _vm.handleQuery(1)
                                },
                              },
                            },
                            [_vm._v("查询")]
                          ),
                          _c(
                            "el-button",
                            {
                              staticClass: "addClass",
                              attrs: {
                                icon: "el-icon-circle-plus-outline",
                                size: "small",
                              },
                              on: {
                                click: function ($event) {
                                  return _vm.handleEdit()
                                },
                              },
                            },
                            [_vm._v("新增 ")]
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "div",
        { ref: "tableContainer" },
        [
          _c(
            "el-table",
            {
              directives: [
                {
                  name: "loading",
                  rawName: "v-loading",
                  value: _vm.loading,
                  expression: "loading",
                },
              ],
              ref: "refundTable",
              attrs: {
                border: "",
                stripe: "",
                "header-cell-style": { "text-align": "center" },
                "cell-style": { "text-align": "center" },
                data: _vm.tableDataList,
                height: _vm.tableHeight,
                "highlight-current-row": "",
              },
              on: { "sort-change": _vm.tableSortChange },
            },
            [
              _c("el-table-column", {
                attrs: { type: "index", width: "50", label: "序号" },
              }),
              _c("el-table-column", {
                attrs: {
                  "min-width": "150",
                  property: "breedName",
                  label: "品种名称",
                  sortable: "custom",
                },
              }),
              _c("el-table-column", {
                attrs: { "min-width": "100", property: "spec", label: "规格" },
              }),
              _c("el-table-column", {
                attrs: { width: "120", property: "orderNo", label: "采购序号" },
              }),
              _c("el-table-column", {
                attrs: {
                  "min-width": "150",
                  property: "groupName",
                  label: "采购组名称",
                  sortable: "custom",
                },
              }),
              _c("el-table-column", {
                attrs: {
                  width: "120",
                  property: "groupOrderNo",
                  label: "采购组序号",
                },
              }),
              _c("el-table-column", {
                key: "selectFlag",
                attrs: {
                  label: "中标状态",
                  align: "center",
                  width: "100",
                  resizable: false,
                  fixed: "right",
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _c("el-switch", {
                          staticClass: "switch",
                          attrs: {
                            "active-color": "#5DB730",
                            "active-text": "是",
                            "inactive-text": "否",
                            "inactive-color": "#B5CCF5",
                            "active-value": 1,
                            "inactive-value": 0,
                          },
                          on: {
                            change: function ($event) {
                              return _vm.handleSelectFlagChange(scope.row)
                            },
                          },
                          model: {
                            value: scope.row.selectFlag,
                            callback: function ($$v) {
                              _vm.$set(scope.row, "selectFlag", $$v)
                            },
                            expression: "scope.row.selectFlag",
                          },
                        }),
                      ]
                    },
                  },
                ]),
              }),
              _c("el-table-column", {
                attrs: { fixed: "right", label: "操作", width: "100" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _c(
                          "el-button",
                          {
                            attrs: { type: "text", size: "small" },
                            on: {
                              click: function ($event) {
                                return _vm.handleEdit(scope.row.id)
                              },
                            },
                          },
                          [_vm._v("编辑")]
                        ),
                        _c(
                          "el-button",
                          {
                            staticStyle: { color: "#F56C6C" },
                            attrs: { type: "text", size: "small" },
                            on: {
                              click: function ($event) {
                                return _vm.delDrug(scope.row)
                              },
                            },
                          },
                          [_vm._v("删除")]
                        ),
                      ]
                    },
                  },
                ]),
              }),
              _vm.tableDataList.length == 0
                ? _c("template", { slot: "empty" }, [
                    _c("img", {
                      attrs: {
                        src: require("@/assets/images/no-booking.svg"),
                        alt: "",
                      },
                    }),
                    _c("p", [_vm._v("暂无数据")]),
                  ])
                : _c("template", { slot: "empty" }, [
                    _c("img", {
                      attrs: {
                        src: require("@/assets/images/no-left-data.svg"),
                        alt: "",
                      },
                    }),
                    _c("p", [_vm._v("未查询到结果，请修改查询条件重试！")]),
                  ]),
            ],
            2
          ),
        ],
        1
      ),
      _c("pagination", {
        directives: [
          {
            name: "show",
            rawName: "v-show",
            value: _vm.total > 0,
            expression: "total>0",
          },
        ],
        attrs: {
          total: _vm.total,
          page: _vm.queryParams.pageNum,
          limit: _vm.queryParams.pageSize,
          "page-sizes": [10, 20, 50, 100],
        },
        on: {
          "update:page": function ($event) {
            return _vm.$set(_vm.queryParams, "pageNum", $event)
          },
          "update:limit": function ($event) {
            return _vm.$set(_vm.queryParams, "pageSize", $event)
          },
          pagination: _vm.handleQuery,
        },
      }),
      _c(
        "el-dialog",
        {
          directives: [{ name: "dialogDrag", rawName: "v-dialogDrag" }],
          attrs: {
            title: "招采批次信息编辑",
            width: "700px",
            visible: _vm.drugEditDialogVisible,
            "close-on-click-modal": false,
          },
          on: {
            "update:visible": function ($event) {
              _vm.drugEditDialogVisible = $event
            },
          },
        },
        [
          _c(
            "el-form",
            {
              ref: "drugEditForm",
              attrs: {
                "status-icon": "",
                model: _vm.drugEditFormData,
                "label-width": "140px",
              },
            },
            [
              _c(
                "el-form-item",
                {
                  attrs: {
                    label: "品种名称:",
                    prop: "breedName",
                    rules: [
                      {
                        required: true,
                        message: "请输入品种名称",
                        trigger: "blur",
                      },
                    ],
                  },
                },
                [
                  _c("el-input", {
                    staticStyle: { width: "500px" },
                    attrs: {
                      placeholder: "请输入品种名称",
                      maxlength: "100",
                      clearable: "",
                      "show-word-limit": "",
                    },
                    model: {
                      value: _vm.drugEditFormData.breedName,
                      callback: function ($$v) {
                        _vm.$set(_vm.drugEditFormData, "breedName", $$v)
                      },
                      expression: "drugEditFormData.breedName",
                    },
                  }),
                ],
                1
              ),
              _c(
                "el-form-item",
                {
                  attrs: {
                    label: "规格:",
                    prop: "spec",
                    rules: [
                      {
                        required: true,
                        message: "请输入规格",
                        trigger: "blur",
                      },
                    ],
                  },
                },
                [
                  _c("el-input", {
                    staticStyle: { width: "500px" },
                    attrs: {
                      placeholder: "请输入规格",
                      maxlength: "200",
                      clearable: "",
                      "show-word-limit": "",
                    },
                    model: {
                      value: _vm.drugEditFormData.spec,
                      callback: function ($$v) {
                        _vm.$set(_vm.drugEditFormData, "spec", $$v)
                      },
                      expression: "drugEditFormData.spec",
                    },
                  }),
                ],
                1
              ),
              _c(
                "el-form-item",
                {
                  attrs: {
                    label: "采购序号:",
                    prop: "orderNo",
                    rules: [
                      {
                        required: true,
                        message: "请输入采购序号",
                        trigger: "blur",
                      },
                    ],
                  },
                },
                [
                  _c("el-input", {
                    staticStyle: { width: "500px" },
                    attrs: {
                      placeholder: "请输入采购序号",
                      maxlength: "20",
                      clearable: "",
                      "show-word-limit": "",
                    },
                    model: {
                      value: _vm.drugEditFormData.orderNo,
                      callback: function ($$v) {
                        _vm.$set(_vm.drugEditFormData, "orderNo", $$v)
                      },
                      expression: "drugEditFormData.orderNo",
                    },
                  }),
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "采购组名称:", prop: "groupName" } },
                [
                  _c("el-input", {
                    staticStyle: { width: "500px" },
                    attrs: {
                      placeholder: "请输入采购组名称",
                      maxlength: "100",
                      clearable: "",
                      "show-word-limit": "",
                    },
                    model: {
                      value: _vm.drugEditFormData.groupName,
                      callback: function ($$v) {
                        _vm.$set(_vm.drugEditFormData, "groupName", $$v)
                      },
                      expression: "drugEditFormData.groupName",
                    },
                  }),
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "采购组序号:", prop: "groupOrderNo" } },
                [
                  _c("el-input-number", {
                    staticStyle: { width: "500px" },
                    attrs: {
                      min: 0,
                      max: 10000,
                      controls: "",
                      "controls-position": "right",
                    },
                    model: {
                      value: _vm.drugEditFormData.groupOrderNo,
                      callback: function ($$v) {
                        _vm.$set(_vm.drugEditFormData, "groupOrderNo", $$v)
                      },
                      expression: "drugEditFormData.groupOrderNo",
                    },
                  }),
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "中标状态:", prop: "selectFlag" } },
                [
                  _c(
                    "el-radio-group",
                    {
                      model: {
                        value: _vm.drugEditFormData.selectFlag,
                        callback: function ($$v) {
                          _vm.$set(_vm.drugEditFormData, "selectFlag", $$v)
                        },
                        expression: "drugEditFormData.selectFlag",
                      },
                    },
                    [
                      _c("el-radio-button", { attrs: { label: 1 } }, [
                        _vm._v("是"),
                      ]),
                      _c("el-radio-button", { attrs: { label: 0 } }, [
                        _vm._v("否"),
                      ]),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "span",
            {
              staticClass: "dialog-footer",
              staticStyle: { "margin-right": "20px" },
              attrs: { slot: "footer" },
              slot: "footer",
            },
            [
              _c(
                "el-button",
                {
                  on: {
                    click: function ($event) {
                      _vm.drugEditDialogVisible = false
                    },
                  },
                },
                [_vm._v("取 消")]
              ),
              _c(
                "el-button",
                {
                  attrs: { type: "primary" },
                  on: { click: _vm.submitEditRecord },
                },
                [_vm._v("确 定")]
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }