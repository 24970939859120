<template>
  <div class="app-container" style="overflow: auto">


    <div ref="headerQuery">
      <el-row class="headerClass">
        <el-col :span="14" style="border-left: 5px solid #0073E9;padding-left: 10px;">集采药品目录
          <span style="padding-left: 10px;font-size: 14px;">{{batchInfo.docName}}</span>
        </el-col>
        <el-col :span="10">
          <el-form :model="queryParams" ref="queryForm" :inline="true" style="float: right">


            <el-form-item label="" prop="searchValue">
              <el-input v-model="queryParams.searchValue"
                        placeholder="请输入药品名称/采购组名称" maxlength="50" clearable
                        @keydown.enter.native="handleQuery(1)"
                         style="width: 300px;"></el-input>
            </el-form-item>

            <el-form-item>
              <el-button type="primary" icon="el-icon-search" size="small" @click="handleQuery(1)">查询</el-button>
              <el-button icon="el-icon-circle-plus-outline" size="small" @click="handleEdit()" class="addClass">新增
              </el-button>
            </el-form-item>
          </el-form>
        </el-col>
      </el-row>
    </div>


    <div ref="tableContainer">
      <el-table
        border
        stripe
        :header-cell-style="{'text-align':'center'}"
        :cell-style="{'text-align':'center'}"
        ref="refundTable"
        :data="tableDataList"
        :height="tableHeight"
        v-loading="loading"
        highlight-current-row
        @sort-change="tableSortChange"
      >
        <el-table-column type="index" width="50" label="序号"></el-table-column>
        <el-table-column min-width="150" property="breedName" label="品种名称" sortable="custom"></el-table-column>
        <el-table-column min-width="100" property="spec" label="规格"></el-table-column>
        <el-table-column width="120" property="orderNo" label="采购序号" ></el-table-column>
        <el-table-column min-width="150" property="groupName" label="采购组名称" sortable="custom"></el-table-column>
        <el-table-column width="120" property="groupOrderNo" label="采购组序号"></el-table-column>
        <el-table-column label="中标状态" align="center" key="selectFlag" width="100" :resizable="false" fixed="right">
          <template slot-scope="scope">
            <el-switch
              class="switch"
              active-color="#5DB730"
              active-text="是"
              inactive-text="否"
              inactive-color="#B5CCF5"
              v-model="scope.row.selectFlag"
              :active-value='1'
              :inactive-value='0'
              @change="handleSelectFlagChange(scope.row)"
            ></el-switch>
          </template>
        </el-table-column>
        <el-table-column fixed="right" label="操作" width="100">
          <template slot-scope="scope">
            <el-button @click="handleEdit(scope.row.id)" type="text" size="small">编辑</el-button>
            <el-button type="text" size="small" @click="delDrug(scope.row)" style="color: #F56C6C;">删除</el-button>
          </template>
        </el-table-column>

        <template slot="empty" v-if="tableDataList.length==0">
          <img src="@/assets/images/no-booking.svg" alt="">
          <p>暂无数据</p>
        </template>
        <template slot="empty" v-else>
          <img src="@/assets/images/no-left-data.svg" alt="">
          <p>未查询到结果，请修改查询条件重试！</p>
        </template>
      </el-table>
    </div>


    <pagination
      v-show="total>0"
      :total="total"
      :page.sync="queryParams.pageNum"
      :limit.sync="queryParams.pageSize"
      @pagination="handleQuery"
      :page-sizes="[10, 20, 50, 100]"
    />

    <el-dialog v-dialogDrag title="招采批次信息编辑" width="700px"
               :visible.sync="drugEditDialogVisible"
               :close-on-click-modal="false"
    >
      <el-form ref="drugEditForm" status-icon :model="drugEditFormData" label-width="140px">


        <el-form-item label="品种名称:" prop="breedName"
                      :rules="[{ required: true, message: '请输入品种名称', trigger: 'blur'}]"
        >
          <el-input placeholder="请输入品种名称" maxlength="100" clearable show-word-limit
                    v-model="drugEditFormData.breedName"
                    style="width: 500px;"
          />
        </el-form-item>

        <el-form-item label="规格:" prop="spec"
                      :rules="[{ required: true, message: '请输入规格', trigger: 'blur'}]"
        >
          <el-input placeholder="请输入规格" maxlength="200" clearable show-word-limit
                    v-model="drugEditFormData.spec"
                    style="width: 500px;"
          />
        </el-form-item>

        <el-form-item label="采购序号:" prop="orderNo"
                      :rules="[{ required: true, message: '请输入采购序号', trigger: 'blur'}]"
        >
          <el-input placeholder="请输入采购序号" maxlength="20" clearable show-word-limit
                    v-model="drugEditFormData.orderNo"
                    style="width: 500px;"
          />
        </el-form-item>

        <el-form-item label="采购组名称:" prop="groupName">
          <el-input placeholder="请输入采购组名称" maxlength="100" clearable show-word-limit
                    v-model="drugEditFormData.groupName"
                    style="width: 500px;"
          />
        </el-form-item>

        <el-form-item label="采购组序号:" prop="groupOrderNo">
          <el-input-number :min="0" :max="10000" controls controls-position="right"
                           v-model="drugEditFormData.groupOrderNo"
                           style="width: 500px;"
          />
        </el-form-item>

        <el-form-item label="中标状态:" prop="selectFlag">
          <el-radio-group v-model="drugEditFormData.selectFlag">
            <el-radio-button :label='1'>是</el-radio-button>
            <el-radio-button :label='0'>否</el-radio-button>
          </el-radio-group>
        </el-form-item>







      </el-form>

      <span slot="footer" class="dialog-footer" style="margin-right:20px;">
        <el-button @click="drugEditDialogVisible=false">取 消</el-button>
        <el-button type="primary" @click="submitEditRecord">确 定</el-button>
      </span>
    </el-dialog>


  </div>
</template>

<script>

import AcrossUtil from '@/utils/acrossUtil'
import {isEmpty} from '@/utils/common'
import {recordDrugList, editRecordDrug, recordDrugInfo,delRecordDrug,recordInfo} from '@/api/dkm/purchaseBatchRecord'
import {delFile} from '@/api/system/fileUpload'
export default {
  name: 'RecordDrugList',
  components: {},
  data() {
    return {
      // 遮罩层
      loading: true,
      tableDataList: [],
      // 总条数
      total: 0,
      // 表格高度
      tableHeight: window.innerHeight - 100 - 100,
      // 查询参数
      queryParams: {
        pageNum: 1,
        batchRecordId:undefined,
        pageSize: 10,
        searchValue:"",
        dateRange: [],
        orderByColumn: undefined,
        isAsc: undefined
      },
      //药品信息编辑弹窗
      drugEditDialogVisible: false,
      // 药品信息编辑表单
      drugEditFormData: {
        selectFlag:1
      },
      //当前批次信息
      batchInfo:{}



    }
  },
  created() {
    const batchRecordId = this.$route.params && this.$route.params.id
    this.queryParams.batchRecordId=batchRecordId
    this.handleQuery(1)
    recordInfo(batchRecordId).then(res=>{
      this.batchInfo=res.data
    })

  },
  activated() {
    //激活时重新设置表格高度
    this.setTableHeight()
  },
  mounted() {
    var _this = this
    window.onresize = () => {
      //通知其他已打开的标签页，重新设置表格高度
      AcrossUtil.$emit('resetTableHeight')
    }

    AcrossUtil.$on('resetTableHeight', function (param) {
      setTimeout(function () {
        _this.setTableHeight()
      }, 300)
    })

  },
  methods: {
    /*设置表格的高度*/
    setTableHeight() {
      // 获取高度值 （内容高+padding+边框）
      let formHeight = this.$refs.headerQuery.offsetHeight
      this.tableHeight = window.innerHeight - formHeight - 150
    },
    /*查询操作*/
    handleQuery(type) {
      if (type == 1) {
        //重新执行查询，从第一页开始
        this.queryParams.pageNum = 1
      }
      let params = this.queryParams
      if (params.dateRange && params.dateRange.length == 2) {
        params.beginTime = params.dateRange[0]
        params.endTime = params.dateRange[1]
      }
      //this.queryParams.beginTime=''
      //this.queryParams.endTime=''
      this.loading = true
      recordDrugList(params).then(res => {
        this.loading = false
        res.rows.forEach(row => {

        })
        this.tableDataList = res.rows
        this.total = res.total
      })
    },
    //处理新增、修改
    handleEdit(recordId) {
      this.uploadFileList=[]
      if (recordId) {
        //编辑
        recordDrugInfo(recordId).then(res => {
          this.drugEditFormData = res.data
          if(!this.drugEditFormData.groupOrderNo){
            this.drugEditFormData.groupOrderNo=undefined
          }

        })
      } else {
        //新增
        this.drugEditFormData = {
          selectFlag:1,
          batchRecordId:this.queryParams.batchRecordId
        }
      }
      this.drugEditDialogVisible = true

    },
    //确定编辑
    submitEditRecord() {
      this.$refs['drugEditForm'].validate(valid => {
        this.drugEditFormData.attachmentFiles=JSON.stringify(this.uploadFileList)
        if (valid) {
          editRecordDrug(this.drugEditFormData).then(res => {
            this.msgSuccess(res.msg)
            this.drugEditDialogVisible = false
            this.handleQuery(1)
          })
        } else {
          this.msgError('请规范填写')
        }
      })


    },
    /*表格排序切换*/
    tableSortChange({column, prop, order}) {
      if (order != null) {
        order = order == 'descending' ? 'desc' : 'asc'
      } else {
        order = ''
      }
      this.queryParams.orderByColumn = prop
      this.queryParams.isAsc = order
      this.handleQuery()
    },
    //删除药品
    delDrug(row){
      let _this = this
      this.$confirm('确认要删除' + '[' + row.breedName + ']'+'"吗?', '警告', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(function() {
        delRecordDrug(row.id).then(response => {
          _this.msgSuccess()
          _this.handleQuery()
        })
      })
    },
    //中标状态变更
    handleSelectFlagChange(row){
      this.$confirm('确认要修改'  + '[' + row.breedName +' '+row.spec+ ']的中标状态吗?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'info'
      }).then(function() {
        editRecordDrug(row)
      }).then(() => {
        this.msgSuccess()
      }).catch(function() {
        row.selectFlag = row.selectFlag == 0 ? 1 : 0
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.el-table .cell .el-form-item {
  margin-bottom: 0px;
}


</style>


<style>


</style>
